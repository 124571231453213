function clamp(min, val, max) {
    if (val < min) val = min;
    if (val > max) val = max;
    return val
}

const Loading = (props) => {
    let ucitavanje = clamp(0, props.ucitavanje, 1.0)
    return (
        <div id="ucitavanje" className={(ucitavanje >= 1.0) ? "sakri-ucitavanje": ""}>
            <div id="ucitavanje-slika">
                <img src={process.env.PUBLIC_URL + '/NEC_PLAVI.PNG'} alt="" className="logo-ucitavanje plavi" />
                <img src={process.env.PUBLIC_URL + '/NEC_BIJELI.PNG'} alt="" className="logo-ucitavanje bijeli" />
            </div>
            <div id="loading-bar">
                <div id="napredak" style={{width: (ucitavanje * 100) + '%'}}></div>
            </div>
        </div>
    )
}

export default Loading