import { Icon } from '@iconify/react';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import Navbar from '../../comps/navbar.js'
import Loading from '../../comps/loading_screen'
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom"

const Slideshow = forwardRef((props, ref) => {
  const [offset, setOffset] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, [width]);

  useEffect(() => {
    function handleScroll() {
      if (width < 768) {
        setOffset(0) // parallax ne radi na mobitelu
      } else {
        setOffset(window.pageYOffset)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [width])

  // Pisac
  let [tekst, postaviTekst] = useState("")
  const t = "SVI\nPUTEVI\nVODE U\nNEC"

  useEffect(() => {
    let pisanje = setTimeout(() => {
      if (tekst.length < t.length) {
        postaviTekst(t.slice(0, tekst.length + 1));
      } else {
        return () => clearTimeout(pisanje)
      }
    }, 100);
  });

  return (
    <header id="slideshow" ref={ref}>
      <div id="naslov">
        <h1 className="nas">
          {tekst}
        </h1>
        <h1 className="nas rozi">
          {tekst}
        </h1>
        <h1 className="nas plavi">
          {tekst}
        </h1>
      </div>
      <img
        src={process.env.PUBLIC_URL + '/pozadina.webp'}
        className="slide-bg-img"
        alt=""
        style={{
          transform: `translateY(${offset * 0.6}px)`,
        }}
        />
    </header>
  )
})

const Onama = forwardRef((props, ref) => {
  const [offset, setOffset] = useState(0)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, [width]);

  useEffect(() => {
    function handleScroll() {
      if (width < 768) {
        setOffset(500) // parallax ne radi na mobitelu
      } else {
        setOffset(window.pageYOffset)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [width])

  return (
    <div id="o-nama" ref={ref}>
      <img
        src={process.env.PUBLIC_URL + '/lisce.webp'}
        alt=""
        style={{
          transform: `translateY(${offset * 0.5 - window.outerHeight / 2}px)`,
        }}
        />
      <article>
        <img src={process.env.PUBLIC_URL + '/slika.jpg'} alt=""/>
        <div>
          <h1>O nama</h1>
          <p>
          Caffe bar i noćni klub Nec od 1994. godine pruža gostima ugodne kavice, smijanje i razgovore preko dana, a noću lude i nezaboravne provode. To traje već generacijama.
Trudimo se da se držimo naše tradicije i želimo svima pružiti ugodnu atmosferu i piće.
U skoro 30 godina tradicije naučili smo da je svakom gostu važna topla riječ i hladno piće, a sve to uz naravno najbolje hitove koji se mogu čuti samo kod nas.
Dođite, uvjerite se da se dobro provoditi može svakog dana u tjednu.
          </p>
        </div>
      </article>
    </div>
  )
})

const Info = forwardRef((props, ref) => {
  return (
    <footer id="info" ref={ref}>
      <div id="info-w">
        <div id="fkontakt" className="infos">
          <h1 className="infoNaslov">KONTAKT</h1>
          <div>
            <Icon icon="material-symbols:phone-in-talk" style={{ marginRight: '0.5em' }} className="infoIspod"/>
            <a className="tel" tabIndex="-1" href="tel:01 6192 301">
              <h2 className="infoIspod">01 6192 301</h2>
            </a>
          </div>
          <div>
            <Icon icon="material-symbols:location-on-outline" style={{ marginRight: '0.5em' }} className="infoIspod"/>
            <h2 className="infoIspod">Veslačka 16, Zagreb</h2>
          </div>
        </div>
        <div id="flogo" className="pc">
          <img src={process.env.PUBLIC_URL + '/NEC_BIJELI.png'} alt="logo"/>
        </div>
        <div id="fvrijeme">
          <h1 className="infoNaslov">RADNO VRIJEME</h1>
          <div>
            <h2 className="infoIspod">
              Radni dan i nedjelja 7:00 - 5:00<br />Petak i subota 7:00 - 6:00
            </h2>
          </div>
        </div>
        <div id="flogo" className="mobitel">
          <img src={process.env.PUBLIC_URL + '/NEC_BIJELI.png'} alt="logo"/>
        </div>
      </div>
    </footer>
  )
})

function Pocetna() {
  const [ucitavanje, postaviUcitavanje] = useState(0)

  const location = useLocation()

  const refPocetna = useRef(null)
  const scrollrefPocetna = () => refPocetna.current?.scrollIntoView({behavior: 'smooth'})
  const refOnama = useRef(null)
  const scrollOnama = () => refOnama.current?.scrollIntoView({behavior: 'smooth'})
  const refInfo = useRef(null)
  const scrollrefInfo = () => refInfo.current?.scrollIntoView({behavior: 'smooth'})

    
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === "/o_nama") {
        scrollOnama();
        navigate('/')
      }
      if (location.pathname === "/kontakt") {
        scrollrefInfo();
        navigate('/')
      }
      if (location.pathname === "/pocetna") {
        scrollrefPocetna();
        navigate('/')
      }
    }, 100)
  }, [location.pathname, navigate])


  let postotak = 0.2
  let delay = 100
  useEffect(() => {
    const interval = setInterval(() => {
      if (ucitavanje >= 1.0) {
        return () => clearInterval(interval);
      } else {
        postaviUcitavanje((ucitavanje) => ucitavanje + postotak)
      }
    }, delay * Math.random());
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
  

  return (
    <>
      <Loading ucitavanje={ucitavanje} />
      <Navbar />
      <div id="sadrzaj">
        <Slideshow ref={refPocetna}/>
        <div className="dijeli roza"></div>
        <Onama ref={refOnama}/>
        <div className="dijeli plava"></div>
        <Info ref={refInfo}/>
      </div>
    </>
  );
}

export default Pocetna;

// POGLEDAJ TODO LISTU