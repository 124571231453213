import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";

const NavbarGumb = (props) => {
    const [podcrta, postaviPodcrtu] = useState(false);

    return (
        <div className="navbar-gumb" onClick={props.onClick}>
            <Link to={props.link} className="navbar-pravi-gumb" onMouseEnter={() => postaviPodcrtu(true)} onMouseLeave={() => postaviPodcrtu(false)}>
                <div className="navbar-gumb-tekst nevidljiv">{props.text}</div>
                <div className="navbar-gumb-tekst vidljiv">{props.text}</div>
            </Link>
            <div id={"p" + props.text} className={`podcrta ${podcrta ? "lebdi" : "nelebdi"}`}></div>
        </div>
    )
}
  
const Navbar = (props) => {
    const [skriveno, postaviSkriveno] = useState(true)
    return (
        <>
            <div id="navbar">
                <NavbarGumb text="POČETNA"link="/pocetna" />
                <NavbarGumb text="O NAMA" link="/o_nama" />
                <NavbarGumb text="POSEBNE PONUDE" link="/posebne_ponude"/>
                <NavbarGumb text="GALERIJA" link="/galerija"/>
                <NavbarGumb text="KONTAKT" link="/kontakt" />
                <a href="https://www.instagram.com/nec_zagreb/" target="_blank" rel="noreferrer" id="insta">
                    <img src={process.env.PUBLIC_URL + '/instagram.svg'} alt="@nec_zagreb" id="insta-logo" className={props.plavi_pc ? "plavi-logo" : "rozi-logo"}/>
                </a>
            </div>
            <div id="hamburger-bar">
                <a href="https://www.instagram.com/nec_zagreb/" target="_blank" rel="noreferrer" id="insta">
                    <img src={process.env.PUBLIC_URL + '/instagram.svg'} alt="@nec_zagreb" id="insta-logo" className={props.plavi_mob ? "plavi-logo" : "rozi-logo"}/>
                </a>
                <button onClick={() => postaviSkriveno(false)}>
                    <div className="crta"></div>
                    <div className="crta"></div>
                    <div className="crta"></div>
                </button>
            </div>
            <div id="nav" className={skriveno ? "skriveno": "vidljivo"}>
                <img src={process.env.PUBLIC_URL + '/NEC_BIJELI.png'} alt="logo"/>
                <NavbarGumb text="POČETNA"link="/pocetna" onClick={() => postaviSkriveno(true)} />
                <NavbarGumb text="O NAMA" link="/o_nama" onClick={() => postaviSkriveno(true)} />
                <NavbarGumb text="POSEBNE PONUDE" link="/posebne_ponude" onClick={() => postaviSkriveno(true)} />
                <NavbarGumb text="GALERIJA" link="/galerija" onClick={() => postaviSkriveno(true)} />
                <NavbarGumb text="KONTAKT" link="/kontakt" onClick={() => postaviSkriveno(true)} />
                <button onClick={() => postaviSkriveno(true)}>
                    <Icon icon="material-symbols:close" id="close" style={{fontSize: "14vw"}}/>
                </button>
            </div>
        </>
    )
}

export default Navbar