import Navbar from "../../comps/navbar.js";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from "../../comps/loading_screen";

// ovdje je logika za prikaz slike
const Slike = (props) => {
  const navigate = useNavigate();
  const [ucitavanje, postaviUcitavanje] = useState(0.0);
  let povecanje = 1 / (props.slike.length - 1);
  let prikaz = [];
  var i = 0;

  const povecaj = () => {
    var delay = 2000;
    setTimeout(() => {
      postaviUcitavanje((ucitavanje) => ucitavanje + povecanje);
    }, delay * Math.random());
  };

  props.slike.forEach((element) => {
    prikaz.push(
      <img
        src={element}
        key={element + i}
        alt="slika NECa"
        className="g-slika"
        onLoad={() => povecaj()}
      />
    );
    i += 1;
  });

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  return (
    <>
      <Loading ucitavanje={ucitavanje} />
      <div id="g-galerija">
        {props.sakri_natrag ? (
          ""
        ) : (
          <button onClick={() => navigate("/galerija")}>
            {width >= 692 ? (
              <Icon
                icon="material-symbols:arrow-back-ios-rounded"
                style={{ fontSize: "2em" }}
                className="infoIspod"
              />
            ) : (
              <Icon
                icon="material-symbols:arrow-circle-left"
                style={{ fontSize: "2em" }}
                className="infoIspod"
              />
            )}
          </button>
        )}
        {prikaz}
      </div>
    </>
  );
};

const Album = (props) => {
  const location = useLocation();
  let images = [];
  for (let i = 0; i < props.kolicina; i++) {
    images.push(process.env.PUBLIC_URL + `/fotografije/${props.ime}/${i}.jpg`);
  }

  const navigate = useNavigate();

  return (
    <>
      {location.pathname === "/galerija/" + props.ime ? (
        <Slike slike={images} sakri_natrag={props.sakri_natrag} />
      ) : (
        <div
          id="g-album"
          onClick={() => {
            navigate("/galerija/" + props.ime);
          }}
        >
          <img
            src={process.env.PUBLIC_URL + `/fotografije/${props.ime}/0.jpg`}
            alt="fotografija"
          />
          <div id="g-atekst">
            <h1>{props.natpis}</h1>
          </div>
        </div>
      )}
    </>
  );
};

const Albumi = (props) => {
  const navigate = useNavigate();
  let albumi = {
    nec: {
      kolicina: 16,
      ime: "NEC",
    },
    blagdani: {
      kolicina: 5,
      ime: "BLAGDANI 2022",
    },
  };
  let prikazi = [];
  let keys = Object.keys(albumi);
  useEffect(() => {
    if (keys.length === 1) {
      navigate(keys[0]);
    }
    // eslint-disable-next-line
  }, []);

  keys.forEach((key) => {
    prikazi.push(
      <Album
        ime={key}
        key={key}
        kolicina={albumi[key]["kolicina"]}
        natpis={albumi[key]["ime"]}
        sakri_natrag={keys.length === 1}
      />
    );
  });
  return (
    <div id="galerija">
      <div id="g-slika">
        <img
          src={process.env.PUBLIC_URL + "/NEC_PLAVI.png"}
          className="g-svijetlost-slika"
          alt="logo"
        />
        <img src={process.env.PUBLIC_URL + "/NEC_BIJELI.png"} alt="logo" />
      </div>
      <h1>Galerija</h1>
      <hr className="g-crta" />
      <div id="g-albumi">{prikazi}</div>
      <hr className="g-crta" />
    </div>
  );
};

export default function Galerija() {
  const [ucitavanje, postaviUcitavanje] = useState(0);
  // eslint-disable-next-line
  let postotak = 0.25;
  let delay = 100;
  useEffect(() => {
    const interval = setInterval(() => {
      if (ucitavanje >= 1.0) {
        return () => clearInterval(interval);
      } else {
        postaviUcitavanje((ucitavanje) => ucitavanje + postotak);
      }
    }, delay * Math.random());
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Loading ucitavanje={ucitavanje} />
      <Navbar plavi_mob={true} />
      <Albumi ucitavanje={ucitavanje} postaviUcitavanje={postaviUcitavanje} />
    </>
  );
}
