import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import Pocetna from './stranice/pocetna/pocetna';
import Pp from './stranice/posebne_ponude/posebne_ponude';
import Galerija from './stranice/galerija/galerija';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Pocetna />} />
          <Route path="/o_nama" element={<Pocetna />} />
          <Route path="/kontakt" element={<Pocetna />} />
          <Route path="/posebne_ponude" element={<Pp />} />
          <Route path="/galerija/*" element={<Galerija />} />
          <Route path="/pocetna" element={<Pocetna />} />
          <Route path="*" element={<Pocetna />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);