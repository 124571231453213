import Navbar from '../../comps/navbar.js'
import { useState, useEffect } from 'react'
import Loading from '../../comps/loading_screen'

export default function Pp() {
    const [ucitavanje, postaviUcitavanje] = useState(0)
    let postotak = 0.2
    let delay = 500
    useEffect(() => {
      const interval = setInterval(() => {
        if (ucitavanje >= 1.0) {
          return () => clearInterval(interval);
        } else {
          postaviUcitavanje((ucitavanje) => ucitavanje + postotak)
        }
      }, delay * Math.random());
      return () => clearInterval(interval);
      // eslint-disable-next-line
    }, []);

    return (
        <>
            <Loading ucitavanje={ucitavanje} />
            <Navbar plavi_mob={true}/>
            <div id="pp">
                <div id="pp-slika">
                    <img src={process.env.PUBLIC_URL + "/NEC_PLAVI.png"} className="pp-svijetlost-slika" alt="" />
                    <img src={process.env.PUBLIC_URL + "/NEC_BIJELI.png"} alt="logo" />
                </div>
                <div id="p-naslov">
                    <h1>POSEBNE PONUDE</h1> 
                    <img src={process.env.PUBLIC_URL + "/pponude.jpg"} alt="" />
                </div>
                <hr />
                <div id="p-info">
                    <img src={process.env.PUBLIC_URL + "/necelja.webp"} alt="Svake nedelje od 17:00 do zatvaranja u 5:00 plateći jednu pivu za 20 kuna dobiješ jednu gratis."/>
                </div>
                <hr />
            </div>
        </>
    )
}

